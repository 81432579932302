@import "../../assets/styles/utils";
// banner
.banner{
  width:100%;
  @include res(padding-top,$header-height-base, $header-height-ratio);
	&.mob{
		@include res(display,none, (xs:block));
	}
  .swiper-container{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    .swiper-slide{
      width: 100%;
      height:100%;
      position: relative;
      .pc{
        width: 100%;
        @include res(display,block,(xs:none));
      }
      .mob{
        width: 100%;
        @include res(display,none,(xs:block));
      }
      .banner-text{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        @include res(display,flex,(xs:none));
        align-items: center;
        justify-content: center;
        flex-flow: column;
        h1{
          color: $color-title;
          @include res(font-size,46px,15 / 46);
          @include res(letter-spacing,10px,1 / 10);
        }
        p{
          color: #fff;
          @include res(font-size,18px,12 / 18);
        }
        ul{
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include res(margin-top,30px,10 / 30);
          li{
            @include res(margin-right,20px,10 / 20);
            &.active{
              a{
                color: $color-title;
              }
            }
            a{
              color: #fff;
              &:hover{
                color: $color-title;
              }
            }
          }
        }
      }
    }
  }
}
// 课程体系
.index-img{
  width:100%;
  @include res(height,395px,160 / 395);
  background-image: url('./images/tixi-bg.png');
  @include res(background-position,50%,100 / 50);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @include res(display,block,(xs:none));
  .mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.25);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    @include res(display,none,(xs:block));
  }
  .container{
    width:100%;
    height: 100%;
    position: relative;
    z-index: 999;
    .desc{
      @include res(width,48%,100 / 48);
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column;
      .info{
        @include res(font-size,16px,12 / 16);
        @include res(line-height,28px,20 / 28);
        @include res(margin-top,30px,15 / 30);
        @include res(color,#333,(xs:#fff));
      }
    }
  }
}
// 课程分类
.classfily{
  @include res(margin-top,60px,20 / 60);
  @include res(margin-bottom,60px,20 / 60);
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      @include res(width,30%,(xs:100%));
      height: auto;
      overflow: hidden;
      @include res(margin-bottom,0,(md:10px));
      .img{
        width: 100%;
        height: 100%;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
          object-fit: cover;
          transition: .3s;
        }
        .mask{
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          @include res(display,flex,(xs:none));
          align-items: center;
          justify-content: center;
          flex-flow: column;
          h1{
            font-weight: bold;
            @include res(font-size,24px,20 / 24);
          }
          .line{
            width: 50px;
            height: 2px;
            background: #fff;
            margin: 0 auto;
            @include res(margin-top,30px,20 / 30);
          }
          .desc{
            @include res(font-size,16px,12 / 16);
            text-align: center;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            @include res(margin-top,30px,20 / 30);
          }
          .more{
            @include res(width,120px);
            @include res(height,36px);
            border: 1px solid #fff;
            @include res(margin-top,50px,30 / 50);
            a{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              @include res(font-size,16px,14 / 16);
              transition: .3s;
            }
          }
        }
        .mob-mask{
          width: 100%;
          height: 35%;
          background: #265d4d;
          position: absolute;
          bottom: 0;
          left: 0;
          @include res(display,none,(xs:flex));
          align-items: center;
          justify-content: center;
          flex-flow: column;
          color: #fff;
          h1{
            font-weight: bold;
            font-size: 20px;
          }
          .desc{
            font-size: 14px;
            text-align: center;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-top: 10px;
          }
          .more{
            @include res(width,120px);
            @include res(height,36px);
            border: 2px solid #fff;
            margin-top: 20px;
            border-radius: 4px;
            a{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              transition: .3s;
            }
          }
        }
      }
      &:hover{
        .img{
          img{
            transform: scale(1.2);
          }
        }
        .more{
          a{
            background: #fff;
            color: $color-main;
          }
        }
      }
    }
  }
}
// 学校概况
.school-profile{
  width:100%;
  @include res(height,470px,200 / 470);
  background-image: url('./images/school-profile.png');
  @include res(background-position,50%,0 / 50);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @include res(display,block,(xs:none));
  .mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.25);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    @include res(display,none,(xs:block));
  }
  .container{
    width:100%;
    height: 100%;
    position: relative;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    .desc{
      @include res(width,56%,100 / 56);
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column;
      .info{
        @include res(font-size,16px,12 / 16);
        @include res(line-height,28px,20 / 28);
        @include res(margin-top,30px,15 / 30);
        @include res(color,#333,(xs:#fff));
      }
    }
  }
}
// 视频和新闻部分
.video_news{
  @include res(padding-top,80px,20 / 80);
  @include res(padding-bottom,100px,20 / 100);
  background: #fff;
  position: relative;
  // 水印
  .shuiyin{
    @include res(width,22%,20 / 22);
    @include res(display,block,(md:none));
    position: absolute;
    @include res(top,-29%,20 / -29);
    right: 0;
    z-index: 9;
    @media (max-width: 415px){
      display: block;
      top: 60%;
      width: 50%;
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .container{
    @include res(display,flex,(sm:block));
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .mob-common-title{
      margin-top: 0;
    }
    .video{
      @include res(width,62%,(sm:100%));
      @include res(height,498px,220 / 498);
      video{
        width:100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .news{
      @include res(width,30%,(sm:100%));
      @include res(margin-top,0,(sm:15px));
      .common-title{
        @include res(justify-content,flex-start,(sm:center));
        span{
          @include res(display,block,(sm:none));
        }
      }
      ul{
        li{
          @include res(width,100%,(sm:80%));
          @include res(margin,0,(sm:0 auto));
          @include res(text-align,left,(sm:center));
          @include res(padding-top,26px,10 / 26);
          @include res(padding-bottom,26px,10 / 26);
          border-bottom: 2px solid #ffc933;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          a{
            @include res(font-size,16px,14 / 16);
            transition: .3s;
          }
          &:hover{
            a{
              color:#ffc933;
            }
          }
        }
      }
    }
  }
}
// 管理团队
.team{
  width:100%;
  background-image: url('./images/team-bg.png');
  @include res(background-position,50%);
  background-size: cover;
  background-repeat: no-repeat;
  @include res(margin-bottom,80px,20 / 80);
  @include res(padding-top,100px,20 / 100);
  @include res(padding-bottom,180px,20 / 180);
  @include res(display,block,(xs:none));
  .common-title{
    justify-content: center;
  }
  .mySwiper{
    position: relative;
    overflow: hidden;
    @include res(margin-top,100px,20 / 100);
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        @media (max-width: 767px){
          width: 100% !important;
        }
        .img{
          width: 100%;
          @include res(height,180px,300 / 180);
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            object-fit: cover;
          }
        }
        .mask{
          width: 100%;
          @include res(height,220px,150 / 220);
          background: rgba(173,176,165,.7);
          color: #fff;
          h1{
            @include res(font-size,20px,16 / 20);
            text-align: center;
            position: relative;
            @include res(padding-top,8px,6 / 8);
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom:-4px;
              left: 50%;
              @include res(width,80px,60 / 80);
              @include res(margin-left,-40px,-30 / -40);
              height: 2px;
              background: #fff;
            }
          }
          p{
            @include res(font-size,16px);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 0 58px;
            @include res(margin-top,10px);
          }
          .desc{
            @include res(font-size,14px,10 / 14);
            @include res(margin-top,8px,6 / 8);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            -webkit-box-orient: vertical;
            padding: 0 20px;
          }
        }
      }
    }
    .swiper-button-next, .swiper-button-prev{
      @include res(width,50px,40 / 50);
      @include res(height,50px,40 / 50);
      border-radius: 50%;
      background: rgba(0,0,0,.5);
      color: #fff;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
      opacity: 1;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
      @include res(font-size,20px);
    }
  }
}
// 师资团队
.teacher{
  width:100%;
  @include res(margin-bottom,80px,20 / 80);
  @include res(display,block,(xs:none));
  position: relative;
  .common-title{
    justify-content: center;
  }
  @media (max-width: 1441px){
    .container{
      width: 1100px;
    }
  }
  .mySwiper{
    position: relative;
    overflow: hidden;
    @include res(margin-top,100px,20 / 100);
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      li{
        cursor: pointer;
        @media (max-width: 767px){
          width: 48% !important;
        }
        &:hover{
          .mask{
            h1{
              color: $color-main;
              &::after{
                width: 100%;
                background: $color-main;
              }
            }
          }
        }
        .img{
          @include res(width,100px,80 / 100);
          @include res(height,100px,80 / 100);
          border-radius: 50%;
          border: 4px solid $color-main;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            object-fit: cover;
          }
        }
        .mask{
          width: 100%;
          @include res(height,220px,150 / 220);
          h1{
            @include res(font-size,20px,16 / 20);
            text-align: left;
            position: relative;
            @include res(padding-top,15px,10 / 15);
            transition: .3s;
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom:-6px;
              left: 0;
              @include res(width,120px,100 / 120);
              height: 2px;
              background: #999594;
              transition: .3s;
            }
          }
          .desc{
            @include res(font-size,14px,10 / 14);
            @include res(margin-top,20px,6 / 20);
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 8;
            line-clamp:8;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .swiper-button-next{
    right: 13%;
    @media (max-width: 1441px){
      right: 8%;
    }
    @media (max-width: 1200px){
      right: 2%;
    }
  }
  .swiper-button-prev{
    left: 13%;
    @media (max-width: 1441px){
      left: 8%;
    }
    @media (max-width: 1200px){
      left: 2%;
    }
  }
  .swiper-button-next, .swiper-button-prev{
    @include res(width,50px,40 / 50);
    @include res(height,50px,40 / 50);
    border-radius: 50%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    display: flex;
  }
  .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
    opacity: 1;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
    @include res(font-size,20px);
  }
}
// 校园概况
.school{
  .common-title{
    justify-content: center;
    @include res(margin-bottom,80px,20 / 80);
    position: relative;
    @include res(display,flex,(xs:none));
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      @include res(left,13%,5 / 13);
      margin-top: -1px;
      @include res(width,340px,100 / 340);
      height: 2px;
      background: $color-title;
      @media (max-width: 415px){
        width: 60px;
        left: 3%;
      }
    }
    &::after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      @include res(right,13%,5 / 13);
      margin-top: -1px;
      @include res(width,340px,100 / 340);
      height: 2px;
      background: $color-title;
      @media (max-width: 415px){
        width: 60px;
        right: 3%;
      }
    }
  }
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      @include res(width,30%,(md:48%));
      height: auto;
      overflow: hidden;
      @include res(margin-bottom,50px,15 / 50);
      .img{
        width: 100%;
        height: 100%;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
          object-fit: cover;
          transition: .3s;
        }
        .mask{
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          h1{
            width: 90%;
            height: 84%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            @include res(font-size,24px,14 / 24);
          }
        }
      }
      &:hover{
        .img{
          img{
            transform: scale(1.2);
          }
        }
        .mask{
          h1{
            border: 1px solid #fff;
          }
        }
      }
    }
  }
}
