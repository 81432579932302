.banner {
  width: 100%;
  padding-top: 100px;
}

@media (max-width: 1359px) {
  .banner {
    padding-top: 90px;
  }
}

@media (max-width: 1023px) {
  .banner {
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .banner {
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .banner {
    padding-top: 60px;
  }
}

.banner.mob {
  display: none;
}

@media (max-width: 767px) {
  .banner.mob {
    display: block;
  }
}

.banner .swiper-container {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.banner .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner .swiper-container .swiper-slide .pc {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .pc {
    display: none;
  }
}

.banner .swiper-container .swiper-slide .mob {
  width: 100%;
  display: none;
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .mob {
    display: block;
  }
}

.banner .swiper-container .swiper-slide .banner-text {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text {
    display: none;
  }
}

.banner .swiper-container .swiper-slide .banner-text h1 {
  color: #fdd900;
  font-size: 46px;
  letter-spacing: 10px;
}

@media (max-width: 1359px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    font-size: 38.25px;
  }
}

@media (max-width: 1023px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    font-size: 30.5px;
  }
}

@media (max-width: 991px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    font-size: 22.75px;
  }
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    font-size: 15px;
  }
}

@media (max-width: 1359px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    letter-spacing: 7.75px;
  }
}

@media (max-width: 1023px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    letter-spacing: 5.5px;
  }
}

@media (max-width: 991px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    letter-spacing: 3.25px;
  }
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text h1 {
    letter-spacing: 1px;
  }
}

.banner .swiper-container .swiper-slide .banner-text p {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 1359px) {
  .banner .swiper-container .swiper-slide .banner-text p {
    font-size: 16.5px;
  }
}

@media (max-width: 1023px) {
  .banner .swiper-container .swiper-slide .banner-text p {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .banner .swiper-container .swiper-slide .banner-text p {
    font-size: 13.5px;
  }
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text p {
    font-size: 12px;
  }
}

.banner .swiper-container .swiper-slide .banner-text ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 1359px) {
  .banner .swiper-container .swiper-slide .banner-text ul {
    margin-top: 25px;
  }
}

@media (max-width: 1023px) {
  .banner .swiper-container .swiper-slide .banner-text ul {
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .banner .swiper-container .swiper-slide .banner-text ul {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text ul {
    margin-top: 10px;
  }
}

.banner .swiper-container .swiper-slide .banner-text ul li {
  margin-right: 20px;
}

@media (max-width: 1359px) {
  .banner .swiper-container .swiper-slide .banner-text ul li {
    margin-right: 17.5px;
  }
}

@media (max-width: 1023px) {
  .banner .swiper-container .swiper-slide .banner-text ul li {
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .banner .swiper-container .swiper-slide .banner-text ul li {
    margin-right: 12.5px;
  }
}

@media (max-width: 767px) {
  .banner .swiper-container .swiper-slide .banner-text ul li {
    margin-right: 10px;
  }
}

.banner .swiper-container .swiper-slide .banner-text ul li.active a {
  color: #fdd900;
}

.banner .swiper-container .swiper-slide .banner-text ul li a {
  color: #fff;
}

.banner .swiper-container .swiper-slide .banner-text ul li a:hover {
  color: #fdd900;
}

.index-img {
  width: 100%;
  height: 395px;
  background-image: url("./images/tixi-bg.png");
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: block;
}

@media (max-width: 1359px) {
  .index-img {
    height: 336.25px;
  }
}

@media (max-width: 1023px) {
  .index-img {
    height: 277.5px;
  }
}

@media (max-width: 991px) {
  .index-img {
    height: 218.75px;
  }
}

@media (max-width: 767px) {
  .index-img {
    height: 160px;
  }
}

@media (max-width: 1359px) {
  .index-img {
    background-position: 62.5%;
  }
}

@media (max-width: 1023px) {
  .index-img {
    background-position: 75%;
  }
}

@media (max-width: 991px) {
  .index-img {
    background-position: 87.5%;
  }
}

@media (max-width: 767px) {
  .index-img {
    background-position: 100%;
  }
}

@media (max-width: 767px) {
  .index-img {
    display: none;
  }
}

.index-img .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
}

@media (max-width: 767px) {
  .index-img .mask {
    display: block;
  }
}

.index-img .container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
}

.index-img .container .desc {
  width: 48%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
}

@media (max-width: 1359px) {
  .index-img .container .desc {
    width: 61%;
  }
}

@media (max-width: 1023px) {
  .index-img .container .desc {
    width: 74%;
  }
}

@media (max-width: 991px) {
  .index-img .container .desc {
    width: 87%;
  }
}

@media (max-width: 767px) {
  .index-img .container .desc {
    width: 100%;
  }
}

.index-img .container .desc .info {
  font-size: 16px;
  line-height: 28px;
  margin-top: 30px;
  color: #333;
}

@media (max-width: 1359px) {
  .index-img .container .desc .info {
    font-size: 15px;
  }
}

@media (max-width: 1023px) {
  .index-img .container .desc .info {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .index-img .container .desc .info {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .index-img .container .desc .info {
    font-size: 12px;
  }
}

@media (max-width: 1359px) {
  .index-img .container .desc .info {
    line-height: 26px;
  }
}

@media (max-width: 1023px) {
  .index-img .container .desc .info {
    line-height: 24px;
  }
}

@media (max-width: 991px) {
  .index-img .container .desc .info {
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .index-img .container .desc .info {
    line-height: 20px;
  }
}

@media (max-width: 1359px) {
  .index-img .container .desc .info {
    margin-top: 26.25px;
  }
}

@media (max-width: 1023px) {
  .index-img .container .desc .info {
    margin-top: 22.5px;
  }
}

@media (max-width: 991px) {
  .index-img .container .desc .info {
    margin-top: 18.75px;
  }
}

@media (max-width: 767px) {
  .index-img .container .desc .info {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .index-img .container .desc .info {
    color: #fff;
  }
}

.classfily {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 1359px) {
  .classfily {
    margin-top: 50px;
  }
}

@media (max-width: 1023px) {
  .classfily {
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .classfily {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .classfily {
    margin-top: 20px;
  }
}

@media (max-width: 1359px) {
  .classfily {
    margin-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  .classfily {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .classfily {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .classfily {
    margin-bottom: 20px;
  }
}

.classfily ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.classfily ul li {
  width: 30%;
  height: auto;
  overflow: hidden;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .classfily ul li {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .classfily ul li {
    margin-bottom: 10px;
  }
}

.classfily ul li .img {
  width: 100%;
  height: 100%;
  position: relative;
}

.classfily ul li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: .3s;
}

.classfily ul li .img .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

@media (max-width: 767px) {
  .classfily ul li .img .mask {
    display: none;
  }
}

.classfily ul li .img .mask h1 {
  font-weight: bold;
  font-size: 24px;
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask h1 {
    font-size: 23px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask h1 {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask h1 {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask h1 {
    font-size: 20px;
  }
}

.classfily ul li .img .mask .line {
  width: 50px;
  height: 2px;
  background: #fff;
  margin: 0 auto;
  margin-top: 30px;
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask .line {
    margin-top: 27.5px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask .line {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask .line {
    margin-top: 22.5px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask .line {
    margin-top: 20px;
  }
}

.classfily ul li .img .mask .desc {
  font-size: 16px;
  text-align: center;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 30px;
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask .desc {
    font-size: 15px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask .desc {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask .desc {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask .desc {
    font-size: 12px;
  }
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask .desc {
    margin-top: 27.5px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask .desc {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask .desc {
    margin-top: 22.5px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask .desc {
    margin-top: 20px;
  }
}

.classfily ul li .img .mask .more {
  width: 120px;
  height: 36px;
  border: 1px solid #fff;
  margin-top: 50px;
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask .more {
    margin-top: 45px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask .more {
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask .more {
    margin-top: 35px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask .more {
    margin-top: 30px;
  }
}

.classfily ul li .img .mask .more a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: .3s;
}

@media (max-width: 1359px) {
  .classfily ul li .img .mask .more a {
    font-size: 15.5px;
  }
}

@media (max-width: 1023px) {
  .classfily ul li .img .mask .more a {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .classfily ul li .img .mask .more a {
    font-size: 14.5px;
  }
}

@media (max-width: 767px) {
  .classfily ul li .img .mask .more a {
    font-size: 14px;
  }
}

.classfily ul li .img .mob-mask {
  width: 100%;
  height: 35%;
  background: #265d4d;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: #fff;
}

@media (max-width: 767px) {
  .classfily ul li .img .mob-mask {
    display: flex;
  }
}

.classfily ul li .img .mob-mask h1 {
  font-weight: bold;
  font-size: 20px;
}

.classfily ul li .img .mob-mask .desc {
  font-size: 14px;
  text-align: center;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}

.classfily ul li .img .mob-mask .more {
  width: 120px;
  height: 36px;
  border: 2px solid #fff;
  margin-top: 20px;
  border-radius: 4px;
}

.classfily ul li .img .mob-mask .more a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: .3s;
}

.classfily ul li:hover .img img {
  transform: scale(1.2);
}

.classfily ul li:hover .more a {
  background: #fff;
  color: #265d4d;
}

.school-profile {
  width: 100%;
  height: 470px;
  background-image: url("./images/school-profile.png");
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: block;
}

@media (max-width: 1359px) {
  .school-profile {
    height: 402.5px;
  }
}

@media (max-width: 1023px) {
  .school-profile {
    height: 335px;
  }
}

@media (max-width: 991px) {
  .school-profile {
    height: 267.5px;
  }
}

@media (max-width: 767px) {
  .school-profile {
    height: 200px;
  }
}

@media (max-width: 1359px) {
  .school-profile {
    background-position: 37.5%;
  }
}

@media (max-width: 1023px) {
  .school-profile {
    background-position: 25%;
  }
}

@media (max-width: 991px) {
  .school-profile {
    background-position: 12.5%;
  }
}

@media (max-width: 767px) {
  .school-profile {
    background-position: 0%;
  }
}

@media (max-width: 767px) {
  .school-profile {
    display: none;
  }
}

.school-profile .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
}

@media (max-width: 767px) {
  .school-profile .mask {
    display: block;
  }
}

.school-profile .container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

.school-profile .container .desc {
  width: 56%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
}

@media (max-width: 1359px) {
  .school-profile .container .desc {
    width: 67%;
  }
}

@media (max-width: 1023px) {
  .school-profile .container .desc {
    width: 78%;
  }
}

@media (max-width: 991px) {
  .school-profile .container .desc {
    width: 89%;
  }
}

@media (max-width: 767px) {
  .school-profile .container .desc {
    width: 100%;
  }
}

.school-profile .container .desc .info {
  font-size: 16px;
  line-height: 28px;
  margin-top: 30px;
  color: #333;
}

@media (max-width: 1359px) {
  .school-profile .container .desc .info {
    font-size: 15px;
  }
}

@media (max-width: 1023px) {
  .school-profile .container .desc .info {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .school-profile .container .desc .info {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .school-profile .container .desc .info {
    font-size: 12px;
  }
}

@media (max-width: 1359px) {
  .school-profile .container .desc .info {
    line-height: 26px;
  }
}

@media (max-width: 1023px) {
  .school-profile .container .desc .info {
    line-height: 24px;
  }
}

@media (max-width: 991px) {
  .school-profile .container .desc .info {
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .school-profile .container .desc .info {
    line-height: 20px;
  }
}

@media (max-width: 1359px) {
  .school-profile .container .desc .info {
    margin-top: 26.25px;
  }
}

@media (max-width: 1023px) {
  .school-profile .container .desc .info {
    margin-top: 22.5px;
  }
}

@media (max-width: 991px) {
  .school-profile .container .desc .info {
    margin-top: 18.75px;
  }
}

@media (max-width: 767px) {
  .school-profile .container .desc .info {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .school-profile .container .desc .info {
    color: #fff;
  }
}

.video_news {
  padding-top: 80px;
  padding-bottom: 100px;
  background: #fff;
  position: relative;
}

@media (max-width: 1359px) {
  .video_news {
    padding-top: 65px;
  }
}

@media (max-width: 1023px) {
  .video_news {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .video_news {
    padding-top: 35px;
  }
}

@media (max-width: 767px) {
  .video_news {
    padding-top: 20px;
  }
}

@media (max-width: 1359px) {
  .video_news {
    padding-bottom: 80px;
  }
}

@media (max-width: 1023px) {
  .video_news {
    padding-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .video_news {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .video_news {
    padding-bottom: 20px;
  }
}

.video_news .shuiyin {
  width: 22%;
  display: block;
  position: absolute;
  top: -29%;
  right: 0;
  z-index: 9;
}

@media (max-width: 1359px) {
  .video_news .shuiyin {
    width: 21.5%;
  }
}

@media (max-width: 1023px) {
  .video_news .shuiyin {
    width: 21%;
  }
}

@media (max-width: 991px) {
  .video_news .shuiyin {
    width: 20.5%;
  }
}

@media (max-width: 767px) {
  .video_news .shuiyin {
    width: 20%;
  }
}

@media (max-width: 1023px) {
  .video_news .shuiyin {
    display: none;
  }
}

@media (max-width: 1359px) {
  .video_news .shuiyin {
    top: -16.75%;
  }
}

@media (max-width: 1023px) {
  .video_news .shuiyin {
    top: -4.5%;
  }
}

@media (max-width: 991px) {
  .video_news .shuiyin {
    top: 7.75%;
  }
}

@media (max-width: 767px) {
  .video_news .shuiyin {
    top: 20%;
  }
}

@media (max-width: 415px) {
  .video_news .shuiyin {
    display: block;
    top: 60%;
    width: 50%;
  }
}

.video_news .shuiyin img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.video_news .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .video_news .container {
    display: block;
  }
}

.video_news .container .mob-common-title {
  margin-top: 0;
}

.video_news .container .video {
  width: 62%;
  height: 498px;
}

@media (max-width: 991px) {
  .video_news .container .video {
    width: 100%;
  }
}

@media (max-width: 1359px) {
  .video_news .container .video {
    height: 428.5px;
  }
}

@media (max-width: 1023px) {
  .video_news .container .video {
    height: 359px;
  }
}

@media (max-width: 991px) {
  .video_news .container .video {
    height: 289.5px;
  }
}

@media (max-width: 767px) {
  .video_news .container .video {
    height: 220px;
  }
}

.video_news .container .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video_news .container .news {
  width: 30%;
  margin-top: 0;
}

@media (max-width: 991px) {
  .video_news .container .news {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .video_news .container .news {
    margin-top: 15px;
  }
}

.video_news .container .news .common-title {
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .video_news .container .news .common-title {
    justify-content: center;
  }
}

.video_news .container .news .common-title span {
  display: block;
}

@media (max-width: 991px) {
  .video_news .container .news .common-title span {
    display: none;
  }
}

.video_news .container .news ul li {
  width: 100%;
  margin: 0;
  text-align: left;
  padding-top: 26px;
  padding-bottom: 26px;
  border-bottom: 2px solid #ffc933;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 991px) {
  .video_news .container .news ul li {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .video_news .container .news ul li {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .video_news .container .news ul li {
    text-align: center;
  }
}

@media (max-width: 1359px) {
  .video_news .container .news ul li {
    padding-top: 22px;
  }
}

@media (max-width: 1023px) {
  .video_news .container .news ul li {
    padding-top: 18px;
  }
}

@media (max-width: 991px) {
  .video_news .container .news ul li {
    padding-top: 14px;
  }
}

@media (max-width: 767px) {
  .video_news .container .news ul li {
    padding-top: 10px;
  }
}

@media (max-width: 1359px) {
  .video_news .container .news ul li {
    padding-bottom: 22px;
  }
}

@media (max-width: 1023px) {
  .video_news .container .news ul li {
    padding-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .video_news .container .news ul li {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .video_news .container .news ul li {
    padding-bottom: 10px;
  }
}

.video_news .container .news ul li a {
  font-size: 16px;
  transition: .3s;
}

@media (max-width: 1359px) {
  .video_news .container .news ul li a {
    font-size: 15.5px;
  }
}

@media (max-width: 1023px) {
  .video_news .container .news ul li a {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .video_news .container .news ul li a {
    font-size: 14.5px;
  }
}

@media (max-width: 767px) {
  .video_news .container .news ul li a {
    font-size: 14px;
  }
}

.video_news .container .news ul li:hover a {
  color: #ffc933;
}

.team {
  width: 100%;
  background-image: url("./images/team-bg.png");
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  padding-top: 100px;
  padding-bottom: 180px;
  display: block;
}

@media (max-width: 1359px) {
  .team {
    margin-bottom: 65px;
  }
}

@media (max-width: 1023px) {
  .team {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .team {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .team {
    margin-bottom: 20px;
  }
}

@media (max-width: 1359px) {
  .team {
    padding-top: 80px;
  }
}

@media (max-width: 1023px) {
  .team {
    padding-top: 60px;
  }
}

@media (max-width: 991px) {
  .team {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .team {
    padding-top: 20px;
  }
}

@media (max-width: 1359px) {
  .team {
    padding-bottom: 140px;
  }
}

@media (max-width: 1023px) {
  .team {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .team {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .team {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .team {
    display: none;
  }
}

.team .common-title {
  justify-content: center;
}

.team .mySwiper {
  position: relative;
  overflow: hidden;
  margin-top: 100px;
}

@media (max-width: 1359px) {
  .team .mySwiper {
    margin-top: 80px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper {
    margin-top: 60px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper {
    margin-top: 20px;
  }
}

.team .mySwiper ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .team .mySwiper ul li {
    width: 100% !important;
  }
}

.team .mySwiper ul li .img {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .img {
    height: 210px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .img {
    height: 240px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .img {
    height: 270px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .img {
    height: 300px;
  }
}

.team .mySwiper ul li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.team .mySwiper ul li .mask {
  width: 100%;
  height: 220px;
  background: rgba(173, 176, 165, 0.7);
  color: #fff;
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask {
    height: 202.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask {
    height: 185px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask {
    height: 167.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask {
    height: 150px;
  }
}

.team .mySwiper ul li .mask h1 {
  font-size: 20px;
  text-align: center;
  position: relative;
  padding-top: 8px;
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask h1 {
    font-size: 19px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask h1 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask h1 {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask h1 {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask h1 {
    padding-top: 7.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask h1 {
    padding-top: 7px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask h1 {
    padding-top: 6.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask h1 {
    padding-top: 6px;
  }
}

.team .mySwiper ul li .mask h1::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  height: 2px;
  background: #fff;
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask h1::after {
    width: 75px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask h1::after {
    width: 70px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask h1::after {
    width: 65px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask h1::after {
    width: 60px;
  }
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask h1::after {
    margin-left: -37.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask h1::after {
    margin-left: -35px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask h1::after {
    margin-left: -32.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask h1::after {
    margin-left: -30px;
  }
}

.team .mySwiper ul li .mask p {
  font-size: 16px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 58px;
  margin-top: 10px;
}

.team .mySwiper ul li .mask .desc {
  font-size: 14px;
  margin-top: 8px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  padding: 0 20px;
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask .desc {
    font-size: 13px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask .desc {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask .desc {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask .desc {
    font-size: 10px;
  }
}

@media (max-width: 1359px) {
  .team .mySwiper ul li .mask .desc {
    margin-top: 7.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper ul li .mask .desc {
    margin-top: 7px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper ul li .mask .desc {
    margin-top: 6.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper ul li .mask .desc {
    margin-top: 6px;
  }
}

.team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

@media (max-width: 1359px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    width: 47.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    width: 45px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    width: 42.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    width: 40px;
  }
}

@media (max-width: 1359px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    height: 47.5px;
  }
}

@media (max-width: 1023px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    height: 45px;
  }
}

@media (max-width: 991px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    height: 42.5px;
  }
}

@media (max-width: 767px) {
  .team .mySwiper .swiper-button-next, .team .mySwiper .swiper-button-prev {
    height: 40px;
  }
}

.team .mySwiper .swiper-button-next.swiper-button-disabled, .team .mySwiper .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.team .mySwiper .swiper-button-next:after, .team .mySwiper .swiper-button-prev:after {
  font-size: 20px;
}

.teacher {
  width: 100%;
  margin-bottom: 80px;
  display: block;
  position: relative;
}

@media (max-width: 1359px) {
  .teacher {
    margin-bottom: 65px;
  }
}

@media (max-width: 1023px) {
  .teacher {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .teacher {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .teacher {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .teacher {
    display: none;
  }
}

.teacher .common-title {
  justify-content: center;
}

@media (max-width: 1441px) {
  .teacher .container {
    width: 1100px;
  }
}

.teacher .mySwiper {
  position: relative;
  overflow: hidden;
  margin-top: 100px;
}

@media (max-width: 1359px) {
  .teacher .mySwiper {
    margin-top: 80px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper {
    margin-top: 60px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper {
    margin-top: 20px;
  }
}

.teacher .mySwiper ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.teacher .mySwiper ul li {
  cursor: pointer;
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li {
    width: 48% !important;
  }
}

.teacher .mySwiper ul li:hover .mask h1 {
  color: #265d4d;
}

.teacher .mySwiper ul li:hover .mask h1::after {
  width: 100%;
  background: #265d4d;
}

.teacher .mySwiper ul li .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #265d4d;
  overflow: hidden;
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .img {
    width: 95px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .img {
    width: 90px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .img {
    width: 85px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .img {
    width: 80px;
  }
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .img {
    height: 95px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .img {
    height: 90px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .img {
    height: 85px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .img {
    height: 80px;
  }
}

.teacher .mySwiper ul li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.teacher .mySwiper ul li .mask {
  width: 100%;
  height: 220px;
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask {
    height: 202.5px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask {
    height: 185px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask {
    height: 167.5px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask {
    height: 150px;
  }
}

.teacher .mySwiper ul li .mask h1 {
  font-size: 20px;
  text-align: left;
  position: relative;
  padding-top: 15px;
  transition: .3s;
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask h1 {
    font-size: 19px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask h1 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask h1 {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask h1 {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask h1 {
    padding-top: 13.75px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask h1 {
    padding-top: 12.5px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask h1 {
    padding-top: 11.25px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask h1 {
    padding-top: 10px;
  }
}

.teacher .mySwiper ul li .mask h1::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 120px;
  height: 2px;
  background: #999594;
  transition: .3s;
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask h1::after {
    width: 115px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask h1::after {
    width: 110px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask h1::after {
    width: 105px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask h1::after {
    width: 100px;
  }
}

.teacher .mySwiper ul li .mask .desc {
  font-size: 14px;
  margin-top: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask .desc {
    font-size: 13px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask .desc {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask .desc {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask .desc {
    font-size: 10px;
  }
}

@media (max-width: 1359px) {
  .teacher .mySwiper ul li .mask .desc {
    margin-top: 16.5px;
  }
}

@media (max-width: 1023px) {
  .teacher .mySwiper ul li .mask .desc {
    margin-top: 13px;
  }
}

@media (max-width: 991px) {
  .teacher .mySwiper ul li .mask .desc {
    margin-top: 9.5px;
  }
}

@media (max-width: 767px) {
  .teacher .mySwiper ul li .mask .desc {
    margin-top: 6px;
  }
}

.teacher .swiper-button-next {
  right: 13%;
}

@media (max-width: 1441px) {
  .teacher .swiper-button-next {
    right: 8%;
  }
}

@media (max-width: 1200px) {
  .teacher .swiper-button-next {
    right: 2%;
  }
}

.teacher .swiper-button-prev {
  left: 13%;
}

@media (max-width: 1441px) {
  .teacher .swiper-button-prev {
    left: 8%;
  }
}

@media (max-width: 1200px) {
  .teacher .swiper-button-prev {
    left: 2%;
  }
}

.teacher .swiper-button-next, .teacher .swiper-button-prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
}

@media (max-width: 1359px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    width: 47.5px;
  }
}

@media (max-width: 1023px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    width: 45px;
  }
}

@media (max-width: 991px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    width: 42.5px;
  }
}

@media (max-width: 767px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    width: 40px;
  }
}

@media (max-width: 1359px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    height: 47.5px;
  }
}

@media (max-width: 1023px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    height: 45px;
  }
}

@media (max-width: 991px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    height: 42.5px;
  }
}

@media (max-width: 767px) {
  .teacher .swiper-button-next, .teacher .swiper-button-prev {
    height: 40px;
  }
}

.teacher .swiper-button-next.swiper-button-disabled, .teacher .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.teacher .swiper-button-next:after, .teacher .swiper-button-prev:after {
  font-size: 20px;
}

.school .common-title {
  justify-content: center;
  margin-bottom: 80px;
  position: relative;
  display: flex;
}

@media (max-width: 1359px) {
  .school .common-title {
    margin-bottom: 65px;
  }
}

@media (max-width: 1023px) {
  .school .common-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .school .common-title {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .school .common-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .school .common-title {
    display: none;
  }
}

.school .common-title::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 13%;
  margin-top: -1px;
  width: 340px;
  height: 2px;
  background: #fdd900;
}

@media (max-width: 1359px) {
  .school .common-title::before {
    left: 11%;
  }
}

@media (max-width: 1023px) {
  .school .common-title::before {
    left: 9%;
  }
}

@media (max-width: 991px) {
  .school .common-title::before {
    left: 7%;
  }
}

@media (max-width: 767px) {
  .school .common-title::before {
    left: 5%;
  }
}

@media (max-width: 1359px) {
  .school .common-title::before {
    width: 280px;
  }
}

@media (max-width: 1023px) {
  .school .common-title::before {
    width: 220px;
  }
}

@media (max-width: 991px) {
  .school .common-title::before {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .school .common-title::before {
    width: 100px;
  }
}

@media (max-width: 415px) {
  .school .common-title::before {
    width: 60px;
    left: 3%;
  }
}

.school .common-title::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 13%;
  margin-top: -1px;
  width: 340px;
  height: 2px;
  background: #fdd900;
}

@media (max-width: 1359px) {
  .school .common-title::after {
    right: 11%;
  }
}

@media (max-width: 1023px) {
  .school .common-title::after {
    right: 9%;
  }
}

@media (max-width: 991px) {
  .school .common-title::after {
    right: 7%;
  }
}

@media (max-width: 767px) {
  .school .common-title::after {
    right: 5%;
  }
}

@media (max-width: 1359px) {
  .school .common-title::after {
    width: 280px;
  }
}

@media (max-width: 1023px) {
  .school .common-title::after {
    width: 220px;
  }
}

@media (max-width: 991px) {
  .school .common-title::after {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .school .common-title::after {
    width: 100px;
  }
}

@media (max-width: 415px) {
  .school .common-title::after {
    width: 60px;
    right: 3%;
  }
}

.school ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.school ul li {
  width: 30%;
  height: auto;
  overflow: hidden;
  margin-bottom: 50px;
}

@media (max-width: 1023px) {
  .school ul li {
    width: 48%;
  }
}

@media (max-width: 1359px) {
  .school ul li {
    margin-bottom: 41.25px;
  }
}

@media (max-width: 1023px) {
  .school ul li {
    margin-bottom: 32.5px;
  }
}

@media (max-width: 991px) {
  .school ul li {
    margin-bottom: 23.75px;
  }
}

@media (max-width: 767px) {
  .school ul li {
    margin-bottom: 15px;
  }
}

.school ul li .img {
  width: 100%;
  height: 100%;
  position: relative;
}

.school ul li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: .3s;
}

.school ul li .img .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.school ul li .img .mask h1 {
  width: 90%;
  height: 84%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
}

@media (max-width: 1359px) {
  .school ul li .img .mask h1 {
    font-size: 21.5px;
  }
}

@media (max-width: 1023px) {
  .school ul li .img .mask h1 {
    font-size: 19px;
  }
}

@media (max-width: 991px) {
  .school ul li .img .mask h1 {
    font-size: 16.5px;
  }
}

@media (max-width: 767px) {
  .school ul li .img .mask h1 {
    font-size: 14px;
  }
}

.school ul li:hover .img img {
  transform: scale(1.2);
}

.school ul li:hover .mask h1 {
  border: 1px solid #fff;
}
